import { url } from "../../App.vue";
// import * as jwt from 'jsonwebtoken'

export const userService = {
    login,
    logout,
};

// Async Login function call when "Login" is clicked
async function login(email, password, body) {

    // Hash password entered
    var sha256 = require('js-sha256');
    var hash = sha256.create()
    hash.update(password)
    hash.hex()

    // Fetch from the Express Back-end, the matching account on LASER based on credentials entered
    const data = await fetch(`${url}/login/${email},${hash}`, {
        method: "GET",
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        body: body && JSON.stringify(body),
        withCredentials: true, 
        credentials: 'include' 
    })
    var user = ""
    await data.json().then(response => user = response)

    // Return promise whether or not credentials match
    return new Promise((resolve, reject) => {
        if (user === "No matching username/password found.") {
            reject(user)
        }else if(user === "Successfully logged in."){
            localstorage()
            resolve(JSON.stringify(user))
        } 
    })
}

async function localstorage(body){

    // var key = `${process.env.VUE_APP_JWT_KEY}`
    const data = await fetch(`${url}/setLocalStorage`, {
        method: "GET",
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        body: body && JSON.stringify(body),
        withCredentials: true, 
        credentials: 'include' 
    })
    var userdata = ""
    await data.json().then(response => userdata = response)

    // Set local storage
    window.localStorage.setItem("title", userdata[0])
    window.localStorage.setItem("division", "")
    window.localStorage.setItem("full_name", userdata[1])
    window.localStorage.setItem("type", userdata[2])
    window.localStorage.setItem("additional_services", userdata[3])
    window.localStorage.setItem("preferred_direction", userdata[4])
    window.localStorage.setItem("list", userdata[6])
    window.localStorage.setItem("logo", userdata[7])
    window.localStorage.setItem("country", "")

    // Log ip address used to login alongside date and time logged in
    const userid = userdata[5]
    await fetch(`${url}/log_ip/${userid}`, {
        method: "GET",
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        body: body && JSON.stringify(body),
        withCredentials: true, 
        credentials: 'include' 
    })
}

async function logout(body) {
    const data = await fetch(`${url}/logout`, {
        method: "GET",
        headers: {
          Accept: "applicaiton/json",
          "Content-Type": "application/json",
        },
        body: body && JSON.stringify(body),
        withCredentials: true, 
        credentials: 'include' 
    })
    await data.json()

}
